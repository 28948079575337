import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import Area from '../common/area-grid/Area';
import AreaGrid from '../common/area-grid/AreaGrid';
import DropzoneContainer from '../common/DropzoneContainer';
import Row from '../common/area-grid/Row';
import DeleteIcon from '../assets/icons/delete.svg';
import { SLIDER_PARAMETERS, ParameterDefinitions } from './const';
import { formatReportData, sendPostRequest, sendReport } from '../util/api';
import cuid from 'cuid';
import AudioCard from '../common/AudioCard';
import ProcessingButtons from '../components/ProcessingButtons';
import ErrorToast from '../common/ErrorToast';
import { MAX_FILE_SIZE } from '../consts';
import ReportButton, { ReportData } from '../common/ReportButton';
import { AudioData } from '../common/types';
import ToggleSwitch from '../common/ToggleSwitch';

interface AudioResultGroup {
  id: string;
  date: Date;
  text: string;
  results: AudioResult[];
}

interface AudioResult {
  id: string;
  audioData: Blob;
}

interface EmbedData {
  file: FileWithPath;
  name: string;
}

type ModelType =
  | 'v1'
  | 'v1-2'
  | 'v1-2-death-stranding'
  | 'v2-vdnet'
  | 'v1.2.1'
  | 'v1.2.1-sgr-v1'
  | 'v1.2.1-sgr-v2'
  | 'v2.0.0'
  | 'v2.1.0';

type ModelInfo = {
  label: string;
};

type TtsParameter = {
  api: string;
  model_type: ModelType;
  tgt: FileWithPath;
  name: string;
  text: string;
  lang: string;
  speed: number;
  similarity: number;
  pitch_variance: number;
  pitch_shift: number;
  nfe?: number;
  use_stable?: boolean;
  tgt_embed?: FileWithPath;
  duration?: number;
  text_guidance?: number;
  solver?: number;
};

const modelInfoMap: Record<ModelType, ModelInfo> = {
  v1: {
    label: 'v1',
  },
  'v1-2': {
    label: 'v1-2',
  },
  'v1-2-death-stranding': {
    label: 'v1-2 Death Stranding',
  },
  'v2-vdnet': {
    label: 'v1.1.2',
  },
  'v1.2.1': {
    label: 'v1.2.1',
  },
  'v1.2.1-sgr-v1': {
    label: 'v1.2.1-sgr-v1',
  },
  'v1.2.1-sgr-v2': {
    label: 'v1.2.1-sgr-v2',
  },
  'v2.0.0': {
    label: 'v2.0.0',
  },
  'v2.1.0': {
    label: 'v2.1.0',
  },
};

const modelTypes = Object.keys(modelInfoMap) as ModelType[];

const API_PATH = process.env.REACT_APP_API_PATH_TTS!;

const TEXT_MAX_LENGTH_MAP = {
  'en-us': 1000,
  ko: 500,
};

const TtsPage = () => {
  const [selectedModelType, setSelectedModelType] = useState<ModelType>(
    modelTypes[0]
  );
  const [audioData, setAudioData] = useState<AudioData | null>(null);
  const [embedData, setEmbedData] = useState<EmbedData | null>(null);
  const [textData, setTextData] = useState('');
  const [language, setLanguage] = useState('ko');
  const [numOfGenerations, setNumOfGenerations] = useState(3);
  const [generationCycle, setGenerationCycle] = useState(8);
  const [results, setResults] = useState<AudioResultGroup[]>([]);
  const [useStable, setUseStable] = useState(true);
  const [useDuration, setUseduration] = useState(false);
  const [duration, setDuration] = useState(1);
  const [textGuidance, setTextGuidance] = useState(1.0);
  const [solver, setSolver] = useState(2);

  // 결과 처리 중 여부
  const [isProcessing, setIsProcessing] = useState(false);

  // 오류 발생 여부
  const [isError, setIsError] = useState(false);

  //슬라이더 값
  const [sliderValues, setSliderValues] = useState<
    Partial<
      Record<
        keyof ParameterDefinitions,
        | ParameterDefinitions[keyof ParameterDefinitions]['defaultValue']
        | undefined
      >
    >
  >({});

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const onAudioSelect = async (files: FileWithPath[]) => {
    const file = files[0];

    setAudioData({
      file: new File([file], file.name, { type: 'audio/wav' }),
      type: 'file',
      name: file.name,
    });
  };

  const onEmbedChange = async (files: FileWithPath[]) => {
    const file = files[0];

    setEmbedData({
      file: new File([file], file.name, { type: 'application/json' }),
      name: file.name,
    });
  };

  useEffect(() => {
    if (audioData) {
      const url = URL.createObjectURL(audioData.file);
      const player = document.getElementById(
        'audio-player'
      ) as HTMLAudioElement;

      // 오디오 플레이어 설정
      if (player) player.src = url;
    }
  }, [audioData]);

  const onAudioDelete = () => {
    setAudioData(null);
  };

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    setTextData(e.target.value);
  };

  const getRequestData = () => {
    if (!embedData && !audioData) return;

    const data = {
      api: 'tts',
      model_type: selectedModelType,
      text: textData,
      lang:
        language === 'ko' &&
        [
          'v1.2.1',
          'v2-vdnet',
          'v1.2.1-sgr-v1',
          'v1.2.1-sgr-v2',
          'v2.0.0',
          'v2.1.0',
        ].includes(selectedModelType)
          ? 'ko-nn'
          : language,
      speed: sliderValues['speed'] ?? SLIDER_PARAMETERS.speed.defaultValue,
      similarity:
        sliderValues['similarity'] ?? SLIDER_PARAMETERS.similarity.defaultValue,
      pitch_variance:
        sliderValues['pitch'] ?? SLIDER_PARAMETERS.pitch.defaultValue,
      pitch_shift:
        sliderValues['pitch_shift'] ??
        SLIDER_PARAMETERS.pitch_shift.defaultValue,
    } as TtsParameter;

    if (audioData) {
      data.name = audioData.name;
      data.tgt = audioData.file;
    } else if (embedData) {
      data.name = embedData.name;
      data.tgt_embed = embedData.file;
    }

    if (
      ['v1.2.1', 'v1.2.1-sgr-v1', 'v1.2.1-sgr-v2', 'v2.0.0', 'v2.1.0'].includes(
        selectedModelType
      )
    ) {
      data.nfe = generationCycle;
      data.use_stable = useStable;
    }

    if (['v2.0.0', 'v2.1.0'].includes(selectedModelType) && useDuration) {
      data.duration = duration;
    }

    if (
      [
        'v2-vdnet',
        'v1.2.1',
        'v1.2.1-sgr-v1',
        'v1.2.1-sgr-v2',
        'v2.0.0',
        'v2.1.0',
      ].includes(selectedModelType)
    ) {
      data.text_guidance = textGuidance;
    }

    if (['v2.0.0', 'v2.1.0'].includes(selectedModelType)) {
      data.solver = solver;
    }

    return data;
  };

  const onSubmit = async () => {
    if (!embedData && !audioData) {
      toast.error('오디오나 임베드 데이터를 선택해주세요.');
      return;
    }

    setIsProcessing(true);

    try {
      const requestData = getRequestData();
      const group: AudioResultGroup = {
        id: cuid(),
        date: new Date(),
        text: textData,
        results: [],
      };

      const abortController = new AbortController();
      setAbortController(abortController);

      for (let i = 0; i < numOfGenerations; i++) {
        const { data } = await sendPostRequest<typeof requestData>(
          API_PATH,
          requestData,
          {
            'Content-Type': 'multipart/form-data',
          },
          {
            responseType: 'arraybuffer',
          },
          abortController
        );

        setIsError(false);

        const r: AudioResult = {
          id: cuid(),
          audioData: new Blob([data], { type: 'audio/wav' }),
        };

        group.results.push(r);
      }

      setResults([group, ...results]);
    } catch (e: any) {
      if (e?.code === 'ERR_CANCELED') {
        return e.code;
      }
      setIsError(true);
      console.error(e);
    } finally {
      setIsProcessing(false);
    }
  };

  // 결과 삭제 시
  const onResultDelete = (groupId: string, id: string) => () => {
    const group = results.find((group) => group.id === groupId);

    if (!group) return;

    const index = group.results.findIndex((result) => result.id === id);
    group.results.splice(index, 1);

    setResults([...results]);
  };

  // make slider sections
  const sliderSections = Object.keys(SLIDER_PARAMETERS).map((key) => {
    const keyOfParameter = key as keyof ParameterDefinitions;
    const sliderDefinition = SLIDER_PARAMETERS[keyOfParameter];
    const value = sliderValues[keyOfParameter] ?? sliderDefinition.defaultValue;
    const { minValue, maxValue } = sliderDefinition;

    const marks = [
      {
        value: minValue,
        label: `${sliderDefinition.minLabel}(${minValue})`,
      },
      {
        value: maxValue,
        label: `${sliderDefinition.maxLabel}(${maxValue})`,
      },
    ];

    return (
      <section key={key}>
        <div className="parameter">
          <div className="inner">
            <Tooltip
              title={sliderDefinition.tooltip}
              arrow
              placement="bottom-start"
            >
              <label>{sliderDefinition.label}</label>
            </Tooltip>
            <span className="value">{value}</span>
          </div>
          <div className="slider-container">
            <Slider
              defaultValue={sliderDefinition.defaultValue}
              value={value}
              min={minValue}
              max={maxValue}
              step={sliderDefinition.step}
              marks={marks}
              onChange={(_, value: number | number[]) => {
                setSliderValues({
                  ...sliderValues,
                  [key]: value,
                });
              }}
              disabled={
                key === 'speed' &&
                ['v2.0.0', 'v2.1.0'].includes(selectedModelType) &&
                useDuration
              }
            />
          </div>
        </div>
      </section>
    );
  });

  const abort = useCallback(() => {
    abortController?.abort();
  }, [abortController]);

  const handleReportSubmit = async ({ user, problem }: ReportData) => {
    if (!selectedModelType) return;
    if (!audioData) return;

    const inputData = await getRequestData();
    if (!inputData) return;

    // Need to send audio data as array.
    // @ts-expect-error
    inputData.tgt = [audioData];

    const data = formatReportData({
      ...inputData,
      user,
      problem,
    });

    const r = await sendReport(data);
    return r;
  };

  const maxLength =
    TEXT_MAX_LENGTH_MAP[language as keyof typeof TEXT_MAX_LENGTH_MAP];

  const generationCycleMarks = [
    { value: 3, label: 'min(3)' },
    { value: 16, label: 'max(16)' },
  ];

  const testGuidanceMarks = [
    { value: 0.0, label: 'min(0.0)' },
    { value: 4.0, label: 'max(4.0)' },
  ];

  return (
    <AreaGrid className="tts">
      <Row>
        <Area
          name="main"
          size="380px"
          minSize="380px"
          maxSize="50%"
          splitterAt="left"
        >
          <div>
            <div
              className="contents"
              style={{
                height: `calc(100% - ${isProcessing ? 120 : 70}px)`,
                boxShadow: 'none',
                paddingRight: '1.5rem',
              }}
            >
              <section className="description">
                <Typography variant="h2" component="h2">
                  TTS
                </Typography>
              </section>
              <div>
                <section>
                  <FormControl fullWidth>
                    <div className="inner">
                      <div className="title">Model Type</div>
                    </div>
                    <Select
                      className="tts-select"
                      value={selectedModelType}
                      onChange={(e) =>
                        setSelectedModelType(e.target.value as ModelType)
                      }
                      fullWidth={true}
                      label=""
                      sx={{
                        marginTop: '0.5em',
                        backgroundColor: '#fff',
                      }}
                    >
                      {modelTypes.map((model) => {
                        return (
                          <MenuItem key={model} value={model}>
                            {modelInfoMap[model].label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </section>
                <section>
                  <div className="inner">
                    <div className="title">Source audio</div>
                  </div>
                  {audioData && (
                    <div className="audio-container">
                      <span>{audioData.name}</span>
                      <button className="btn-delete" onClick={onAudioDelete}>
                        <img src={DeleteIcon} alt="Delete source audio." />
                      </button>
                      <audio
                        controls
                        id="audio-player"
                        title={audioData.name}
                      />
                    </div>
                  )}
                  <DropzoneContainer
                    successCallback={onAudioSelect}
                    maxSize={MAX_FILE_SIZE}
                    accept={{
                      'audio/*': [],
                    }}
                  >
                    <p>
                      <UploadFileIcon fontSize="small" />
                      <FormattedMessage
                        id="dropzone"
                        defaultMessage="Drop files or click here"
                      />
                    </p>
                  </DropzoneContainer>
                </section>
                {selectedModelType === 'v1.2.1' && (
                  <section>
                    <div className="inner">
                      <div className="title">Source embed</div>
                    </div>
                    {embedData && (
                      <div className="audio-container">
                        <span>{embedData.name}</span>
                      </div>
                    )}
                    <DropzoneContainer
                      successCallback={onEmbedChange}
                      maxSize={MAX_FILE_SIZE}
                      accept={{
                        'application/json': [],
                      }}
                    >
                      <p>
                        <UploadFileIcon fontSize="small" />
                        <FormattedMessage
                          id="dropzone"
                          defaultMessage="Drop files or click here"
                        />
                      </p>
                    </DropzoneContainer>
                  </section>
                )}
                <section>
                  <div className="inner">
                    <div className="title">Text</div>
                  </div>
                  <div>
                    <textarea
                      value={textData}
                      onChange={onTextChange}
                      maxLength={maxLength}
                    ></textarea>
                    {maxLength && (
                      <div className="note">
                        {maxLength.toLocaleString()} characters max
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <div className="inner">
                    <FormControl>
                      <label className="language-label">Language</label>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={language}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setLanguage(event.target.value)
                        }
                        sx={{ marginTop: '0.5rem' }}
                      >
                        <FormControlLabel
                          value="ko"
                          control={<Radio />}
                          label="Korean"
                          sx={{
                            fontSize: '0.8rem',
                            color: '#3a3a3a',
                            fontWeight: 600,
                          }}
                        />
                        <FormControlLabel
                          value="en-us"
                          control={<Radio />}
                          label="English"
                          sx={{
                            fontSize: '0.8rem',
                            color: '#3a3a3a',
                            fontWeight: 600,
                          }}
                        />
                        {(selectedModelType === 'v1.2.1' ||
                          selectedModelType === 'v2-vdnet' ||
                          selectedModelType === 'v1.2.1-sgr-v1' ||
                          selectedModelType === 'v1.2.1-sgr-v2' ||
                          selectedModelType === 'v2.0.0' ||
                          selectedModelType === 'v2.1.0') && (
                          <FormControlLabel
                            value="ja"
                            control={<Radio />}
                            label="Japanese"
                            sx={{
                              fontSize: '0.8rem',
                              color: '#3a3a3a',
                              fontWeight: 600,
                            }}
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </section>
                {[
                  'v1.2.1',
                  'v1.2.1-sgr-v1',
                  'v1.2.1-sgr-v2',
                  'v2.0.0',
                  'v2.1.0',
                ].includes(selectedModelType) && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'turbo 모델을 사용할지 여부'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Stable mode</label>
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <ToggleSwitch
                            id={'input-useStable'}
                            checked={useStable}
                            onChange={() => setUseStable(!useStable)}
                          />
                        }
                        label={''}
                        sx={{
                          paddingLeft: '207px',
                        }}
                      />
                    </div>
                  </div>
                )}
                {['v2.0.0', 'v2.1.0'].includes(selectedModelType) && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'Duration 을 사용 할지 여부'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Use Duration</label>
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <ToggleSwitch
                            id={'input-useDuration'}
                            checked={useDuration}
                            onChange={() => {
                              setUseduration(!useDuration);
                              if (!useDuration) {
                                setDuration(1);
                              }
                            }}
                          />
                        }
                        label={''}
                        sx={{
                          paddingLeft: '203px',
                        }}
                      />
                    </div>
                  </div>
                )}
                {['v2.0.0', 'v2.1.0'].includes(selectedModelType) &&
                  useDuration && (
                    <div className="parameter">
                      <div className="inner">
                        <Tooltip
                          title={'몇 초짜리 음원을 생성할 것인 지정합니다.'}
                          arrow
                          placement="bottom-start"
                        >
                          <label>Duration</label>
                        </Tooltip>
                        <FormControlLabel
                          control={
                            <TextField
                              variant="outlined"
                              placeholder="Enter duration in seconds"
                              type="number"
                              fullWidth
                              value={duration}
                              onChange={(e) =>
                                setDuration(Number(e.target.value))
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{ marginRight: '8px' }}
                                  >
                                    sec
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                step: '0.1',
                                min: '1.0',
                                max: '60.0',
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  height: '36px',
                                  padding: '0 4px',
                                },
                              }}
                            />
                          }
                          label={''}
                          className="duration-input"
                          sx={{
                            width: '0',
                          }}
                        />
                      </div>
                    </div>
                  )}
                {selectedModelType === 'v2.0.0' && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'Duration 을 사용 할지 여부'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Use Duration</label>
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <ToggleSwitch
                            id={'input-useDuration'}
                            checked={useDuration}
                            onChange={() => {
                              setUseduration(!useDuration);
                              if (!useDuration) {
                                setDuration(1);
                              }
                            }}
                          />
                        }
                        label={''}
                        sx={{
                          paddingLeft: '203px',
                        }}
                      />
                    </div>
                  </div>
                )}
                {useDuration && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'몇 초짜리 음원을 생성할 것인 지정합니다.'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Duration</label>
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <TextField
                            variant="outlined"
                            placeholder="Enter duration in seconds"
                            type="number"
                            fullWidth
                            value={duration}
                            onChange={(e) =>
                              setDuration(Number(e.target.value))
                            }
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                height: '36px',
                                padding: '0 4px',
                              },
                            }}
                          />
                        }
                        label={''}
                        className="duration-input"
                      />
                    </div>
                  </div>
                )}
                <div className="parameter">
                  <div className="inner">
                    <label>Number of Generations</label>

                    <span className="value">{numOfGenerations}</span>
                  </div>
                  <div className="slider-container">
                    <Slider
                      defaultValue={numOfGenerations}
                      value={numOfGenerations}
                      min={1}
                      max={5}
                      step={1}
                      onChange={(_, value: number | number[]) => {
                        setNumOfGenerations(value as number);
                      }}
                    />
                  </div>
                </div>
                {sliderSections}
                {[
                  'v2-vdnet',
                  'v1.2.1',
                  'v1.2.1-sgr-v1',
                  'v1.2.1-sgr-v2',
                  'v2.0.0',
                  'v2.1.0',
                ].includes(selectedModelType) && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'얼마나 텍스트를 따를 것인지 정도'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Text Guidance</label>
                      </Tooltip>
                      <span className="value">{textGuidance}</span>
                    </div>
                    <div className="slider-container">
                      <Slider
                        defaultValue={textGuidance}
                        value={textGuidance}
                        min={0.0}
                        max={4.0}
                        step={0.1}
                        marks={testGuidanceMarks}
                        onChange={(_, value: number | number[]) => {
                          setTextGuidance(value as number);
                        }}
                      />
                    </div>
                  </div>
                )}
                {[
                  'v1.2.1',
                  'v1.2.1-sgr-v1',
                  'v1.2.1-sgr-v2',
                  'v2.0.0',
                  'v2.1.0',
                ].includes(selectedModelType) && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'몇 번에 걸쳐서 생성할지를 정합니다.'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Generation Cycle</label>
                      </Tooltip>
                      <span className="value">{generationCycle}</span>
                    </div>
                    <div className="slider-container">
                      <Slider
                        defaultValue={generationCycle}
                        value={generationCycle}
                        min={3}
                        max={16}
                        step={1}
                        marks={generationCycleMarks}
                        onChange={(_, value: number | number[]) => {
                          setGenerationCycle(value as number);
                        }}
                      />
                    </div>
                  </div>
                )}
                {['v2.0.0', 'v2.1.0'].includes(selectedModelType) && (
                  <section>
                    <div className="inner">
                      <FormControl>
                        <Tooltip
                          title={'미분 방정식 종류를 선택합니다.'}
                          arrow
                          placement="bottom-start"
                        >
                          <label className="language-label">ODE Solver</label>
                        </Tooltip>
                        <RadioGroup
                          row
                          aria-labelledby="row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={solver}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setSolver(Number(event.target.value))
                          }
                          sx={{ marginTop: '0.5rem' }}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Euler"
                            sx={{
                              fontSize: '0.8rem',
                              color: '#3a3a3a',
                              fontWeight: 600,
                            }}
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Midpoint"
                            sx={{
                              fontSize: '0.8rem',
                              color: '#3a3a3a',
                              fontWeight: 600,
                            }}
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="rk4"
                            sx={{
                              fontSize: '0.8rem',
                              color: '#3a3a3a',
                              fontWeight: 600,
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </section>
                )}
              </div>
            </div>
            <div
              style={{
                padding: '1em',
                boxSizing: 'border-box',
              }}
            >
              <ProcessingButtons
                isDisabled={false}
                onAbort={abort}
                onSubmit={onSubmit}
                isProcessing={isProcessing}
              />
            </div>
          </div>
        </Area>

        <Area name="contents-container">
          <AreaGrid className="contents-container">
            <Row>
              <Area name="contents">
                <div className="main">
                  <section>
                    <ErrorToast
                      isOpen={isError}
                      updateIsOpen={(status: boolean) => setIsError(status)}
                    />
                  </section>

                  <section
                    className="results-container"
                    style={{ paddingBottom: '2rem' }}
                  >
                    {results.map((group) => {
                      const audioTitle = group.text
                        .replace(/\s/gim, '')
                        .slice(0, 18);

                      return (
                        <div
                          key={group.id}
                          className="result-group"
                          style={{
                            padding: '1rem',
                          }}
                        >
                          <h3 className="text" style={{ color: '#000' }}>
                            {group.text}
                          </h3>
                          {group.results.map((r) => (
                            <AudioCard
                              key={r.id}
                              title={group.text}
                              onDelete={onResultDelete(group.id, r.id)}
                              audioId="result-player"
                              audioTitle={audioTitle}
                              audioUrl={r.audioData}
                            ></AudioCard>
                          ))}
                        </div>
                      );
                    })}
                  </section>

                  <ReportButton submitCallback={handleReportSubmit} />
                </div>
              </Area>
            </Row>
          </AreaGrid>
        </Area>
      </Row>
    </AreaGrid>
  );
};

export default TtsPage;
